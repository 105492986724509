import wrapWithProvider from "./wrap-with-provider"
import { changeDynamicData } from "./src/scripts/helpers"
import { setCurrency } from "./src/state/slices/currencySlice"
import store from "./src/state/configureStore"

export const wrapRootElement = wrapWithProvider

const onRouteUpdate = ({location}) => {
	const currency = JSON.parse(localStorage.getItem('currency'))

	// Change dynamic prices pagewide
	if (currency && currency.length && currency !== 'EUR') {
		changeDynamicData(currency)
	}

	// Change currencies upon visiting foreign currency pages
	if (location.pathname && location.pathname.startsWith('/irish-hampers-to-uk')) {
		store.dispatch(setCurrency('GBP'))
		changeDynamicData('GBP')
	}
	else if (location.pathname.startsWith('/irish-hampers-to-america')) {
		store.dispatch(setCurrency('USD'))
		changeDynamicData('USD')
	}
	else if (location.pathname.startsWith('/irish-hampers-to-australia')) {
		store.dispatch(setCurrency('AUD'))
		changeDynamicData('AUD')
	}

	
}

export { onRouteUpdate }
