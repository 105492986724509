import { createSlice } from '@reduxjs/toolkit'

const defaultCurrency = 'EUR'

// Function to load state from localStorage
const loadStateFromLocalStorage = () => {
	if (typeof window !== 'undefined') {
		try {
			const serializedState = localStorage.getItem('currency')
			if (serializedState === null) {
				return defaultCurrency // Provide a default currency value if none is found
			}
			return JSON.parse(serializedState)
		} catch (err) {
			console.error('Error loading state from localStorage:', err)
		}
	}
	return defaultCurrency // Provide a default currency value if an error occurs
}

const initialState = loadStateFromLocalStorage()

const currencySlice = createSlice({
	name: 'currency',
	initialState,
	reducers: {
		setCurrency: (state, action) => {
			if (typeof window !== 'undefined') {
				try {
					localStorage.setItem('currency', JSON.stringify(action.payload))
				} catch (err) {
					console.error('Error saving state to localStorage:', err)
				}
			}
			return action.payload
		},
	},
})

export const { setCurrency } = currencySlice.actions

export default currencySlice.reducer