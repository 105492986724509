import { useSelector } from 'react-redux'
const Settings = require('../data/site-settings.json')

const conversionRateGbp = Settings.availableCurrencies.GBP.conversionRate
const conversionRateUsd = Settings.availableCurrencies.USD.conversionRate
const conversionRateAud = Settings.availableCurrencies.AUD.conversionRate

const vatFactor = 1.23

/**
 * Round and fix number to 2 digits
 * 
 * @param {number}	number
 */
export const roundAndFix = (number) => {
	// Round the number correctly
	let _number = +(Math.round(number + 'e+2') + 'e-2')

	// Return the number with 2 decimal places
	return _number.toFixed(2) // type: String
}

/**
 * Remove VAT from number
 * 
 * @param {number}	number
 */
export const removeVatFrom = number => {
	// Add VAT
	let numberWithoutVat = +number / vatFactor

	return roundAndFix(numberWithoutVat) // type: String
}

/**
 * Add VAT to number
 * 
 * @param {number}	number
 */
export const addVatTo = number => {
	// Add VAT
	let numberWithVat = +number * vatFactor

	return roundAndFix(numberWithVat) // type: String
}

/**
 * Change dynamic starting prices
 */
export const changeDynamicStartingPrices = (selectedCurrency) => {
	let match = document.getElementsByClassName('dynamic-price')

	Array.from(match).forEach(element => {
		const basePrice = element.getAttribute('data-base-price')
		let symbol = '&euro;'
		let price = basePrice
		
		if (selectedCurrency === 'GBP') {
			symbol = '&pound;'
			price = Math.ceil(basePrice / conversionRateGbp * 1.02)
		}
		else if (selectedCurrency === 'USD') {
			symbol = '&dollar;'
			price = Math.ceil(basePrice / conversionRateUsd * 1.02)
		}
		else if (selectedCurrency === 'AUD') {
			symbol = 'AU&dollar;'
			price = Math.ceil(basePrice / conversionRateAud * 1.02)
		}

		element.innerHTML = symbol + price
	})
}

/**
 * Change all dynamic data
 */
export const changeDynamicData = (selectedCurrency) => {
	changeDynamicStartingPrices(selectedCurrency)
}